import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SettingsService } from '../../../@core/utils';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'smart-auth',
  templateUrl: './auth.component.html',
  styles: []
})
export class AuthComponent implements OnInit {

  constructor(
    private router: Router,
    private cookieService: CookieService,
    public settings: SettingsService,
    private platformLocation: PlatformLocation,
  ) { }

  ngOnInit() {
  }

  logout() {
    const hostname = (this.platformLocation as any).location.hostname;
    this.cookieService.delete(this.settings.getAppSetting('cookie'), '/', hostname);
    this.settings.setUserSetting('login', []);
    this.settings.setUserSetting('token', '');
    this.router.navigate(['login']);
  }

}
