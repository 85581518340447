import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SettingsService } from '../../../@core/utils';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { PlatformLocation } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'smart-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  valForm: FormGroup;
  existsUser = true;
  isLogging = false;
  isLoggingG = true;
  isLoggingF = true;

  // ngx-toastr config
  toastConfig = {
    extendedTimeOut: 2000,
    preventDuplicates: true,
    progressBar: true,
  };

  constructor(
    fb: FormBuilder,
    public settings: SettingsService,
    private router: Router,
    private http: HttpClient,
    private titleService: Title,
    private cookieService: CookieService,
    private platformLocation: PlatformLocation,
    private jwtHelper: JwtHelperService,
    private toastr: ToastrService,
  ) {
    this.titleService.setTitle(`Login | ${this.settings.getAppSetting('name')}`);

    this.valForm = fb.group({
      txtUsername: [null, Validators.required],
      txtPassword: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.existsUser = this.cookieService.check(this.settings.getAppSetting('cookie'));
    if (this.existsUser) { this.router.navigate(['dashboard']); }
  }

  setCookie(token: any) {
    const decodeToken = this.jwtHelper.decodeToken(token);
    const hostname = (this.platformLocation as any).location.hostname;
    const exp = new Date(decodeToken.exp * 1000);
    this.settings.setUserSetting('login', decodeToken.user);
    this.settings.setUserSetting('token', token);
    const dataEncrypt = CryptoJS.AES.encrypt(token, this.settings.getAppSetting('secret'));
    this.cookieService.set(this.settings.getAppSetting('cookie'), dataEncrypt.toString(), exp, '/', hostname, false, 'Strict');
    this.isLogging = false;
    this.greetings();
    this.router.navigate(['dashboard']);
  }

  greetings() {
    const self = this;
    const time = (new Date()).getHours();
    let greeting = '';

    if (time >= 0 && time < 12) {
      greeting = 'Buenos días';
    } else if (time >= 12 && time < 18) {
      greeting = 'Buenas tardes';
    } else {
      greeting = 'Buenas noches';
    }
    setTimeout(() => {
      self.toastr.info(`${greeting} ${self.settings.getUserSetting('login').firstname}!`, '', self.toastConfig);
    }, 2000);
  }

  onSubmit() {
    console.log(this.valForm);

    const value = this.valForm.value;

    for (const c in this.valForm.controls) {
      if (this.valForm.controls.hasOwnProperty(c)) {
        this.valForm.get(c).markAsTouched();
      }
    }

    if (this.valForm.valid) {
      this.isLogging = true;
      this.http.post(`${this.settings.getAPISetting('url')}auth`, {
        username: value.txtUsername,
        password: value.txtPassword,
      }).subscribe(data => {
        // tslint:disable-next-line:no-string-literal
        this.setCookie(data['token']);
      }, error => {
        this.isLogging = false;
        this.toastr.error(error.error.response, 'Error', this.toastConfig);
      });
    }
  }

  gotoHome() {
    this.router.navigate(['']);
  }

  gotoRecovery() {
    this.router.navigate(['recovery']);
  }

}
