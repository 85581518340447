import { Injectable } from '@angular/core';
import { Socket } from './_websocket';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private websockets: Map<string, Socket>;

  constructor() {
    this.websockets = new Map<string, Socket>();
  }

  public open(url): Socket {
    if (!this.checkExistence(url)) {
      const socket = new Socket(url);
      this.websockets.set(url, socket);
      return socket;
    }
  }

  private checkExistence(url) {
    this.websockets.forEach((key, value) => {
      if (key === url) {
        console.warn('a websocket with same url has existed');
        return true;
      }
    });
    return false;
  }
}
