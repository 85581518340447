export const APP_CONFIG = {

  app: 'Spark Metrics',
  appName: 'Spark Metrics',
  user: 'Dr. Codex Lantern',
  email: 'drlantern@gotbootstrap.com',
  twitter: 'codexlantern',
  avatar: 'avatar-admin.png',
  version: '4.0.0',
  bs4v: '4.3',
  logo: 'logo.png',
  logoM: 'logo.png',
  // tslint:disable-next-line:max-line-length
  copyright: `${new Date().getFullYear()} © Spark Metrics by&nbsp;<a href="https://proyectosees.com" class="text-primary fw-500" title="EES" target="_blank">Eficiencia Energetica y Seguridad S.A. de C.V.</a> ~ Impulsed by&nbsp;<a href="http://www.fondepro.gob.sv/" class="text-primary fw-500" title="FONDEPRO" target="_blank">FONDEPRO</a>`,
};
