class Subscribe {
  public type: number; // then: 0, message: 1, catch: 2, finally: 3.
  public f: (text: any, d?: any, socket?: Socket, event?: Event) => any;
  constructor(_type: number, _f: (text: any, d?: any, socket?: Socket, event?: Event) => any) {
    this.type = _type;
    this.f = _f;
  }
}

export class Socket {
  private websocket: WebSocket;
  private _state: string;
  private _passingData: any;
  private _receiveData: any;
  private _subscribes: Subscribe[];

  constructor(private url: string) {
    try {
      this.websocket = new WebSocket(url);
    } catch (e) {
      console.error(e);
    }
    this._state = 'closed';
    this._subscribes = [];

    this.websocket.addEventListener('open', (e) => {
      this.subscribeLoop(true);
    });

    this.websocket.addEventListener('error', (e) => {
      console.error('websocket onError');
    });

    this.websocket.addEventListener('message', (e) => {
      this._receiveData = e;
      this.subscribeLoop(false);
    });

    this.websocket.addEventListener('close', (e) => {
      this._state = 'closed';
      console.warn('websocket onClose');
    });
  }

  public then(f: (d: any, socket?: Socket) => any): Socket {
    this._subscribes.push(new Subscribe(0, f));
    return this;
  }

  public message(f: (text: any, d?: any, socket?: Socket, event?: Event) => any): Socket {
    this._subscribes.push(new Subscribe(1, f));
    return this;
  }

  public catch(): Socket {
    return this;
  }

  public finally(): Socket {
    return this;
  }

  public send(message: string): Socket {
    try {
      this.websocket.send(message);
    } catch (e) {
      console.error(e);
    }
    return this;
  }

  public close(): void {
    this.websocket.close();
  }

  public subscribeLoop(init: boolean): void {
    for (let i = 0, len = this._subscribes.length; i < len; i++) {
      const sub = this._subscribes[i];

      if (sub.type === 0) {
        this._passingData = sub.f(this._passingData, this);
        if (init === true) { this._subscribes[i] = new Subscribe(0, () => { }); }
      }
      if (sub.type === 1) {
        if (init === true) { break; }
        this._passingData = sub.f(this._receiveData.data, this._passingData, this, this._receiveData);
      }
    }
  }

  public get state() {
    return this._state;
  }
}
