import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../@core/utils';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'smart-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    public settings: SettingsService,
    private titleService: Title,
    private router: Router,
  ) {
    this.titleService.setTitle(`Login | ${this.settings.getAppSetting('name')}`);
  }

  ngOnInit() {

  }

  goHome() {
    this.router.navigate(['']);
  }

}
