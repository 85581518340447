import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './@core/core.module';

import { LoginComponent } from './views/pages/login/login.component';
import { HomeComponent } from './views/home/home.component';
import { NotFoundComponent } from './views/pages/not-found/not-found.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

const APP_COMPONENTS = [
  LoginComponent,
  HomeComponent,
];

const JWT_OPTIONS: JwtModuleOptions = {
  config: {
    whitelistedDomains: ['localhost', 'spark.seesdata.com'],
  },
};

@NgModule({
  declarations: [
    AppComponent,
    ...APP_COMPONENTS,
    HomeComponent,
    NotFoundComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,

    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    LeafletModule.forRoot(),

    FormsModule,
    ReactiveFormsModule,

    JwtModule.forRoot(JWT_OPTIONS),

    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
