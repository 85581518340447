import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { MainComponent } from './@theme/layout/main/main.component';
import { LayoutModule } from './@theme/layout/layout.module';

import { LoginComponent } from './views/pages/login/login.component';
import { HomeComponent } from './views/home/home.component';
import { NotFoundComponent } from './views/pages/not-found/not-found.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login',
    },
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'dashboard',
        component: HomeComponent,
        data: {
          breadcrumbs: ['Dashboard'],
        },
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module')
          .then(m => m.ProfileModule),
      },
      {
        path: 'clients',
        loadChildren: () => import('./views/clients/clients.module')
          .then(m => m.ClientsModule),
      },
      {
        path: 'metrics',
        loadChildren: () => import('./views/metrics/metrics.module')
          .then(m => m.MetricsModule),
      },
      {
        path: 'controls',
        loadChildren: () => import('./views/controls/controls.module')
          .then(m => m.ControlsModule),
      },
      {
        path: 'analysis',
        loadChildren: () => import('./views/analysis/analysis.module')
          .then(m => m.AnalysisModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module')
          .then(m => m.ReportsModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module')
          .then(m => m.SettingsModule),
      },
    ]
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    data: {
      title: 'Error 404',
    },
  },
  // Handle 404 errors
  { path: '**', component: NotFoundComponent },
];

const config: ExtraOptions = {
  useHash: false,
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  imports: [LayoutModule, RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
