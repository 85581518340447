import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/@core/utils';

@Component({
  selector: 'smart-logo',
  templateUrl: './logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent {

  constructor(
    private router: Router,
    public settings: SettingsService,
  ) {}

  openShortcut($event: MouseEvent) {
    $event.preventDefault();
    this.router.navigate(['dashboard']);
  }

}
