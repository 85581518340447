import { Component, OnInit, NgZone, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SettingsService } from '../../@core/utils';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';
import { icon, latLng, marker, polyline, tileLayer } from 'leaflet';
import * as moment from 'moment';
import * as L from 'leaflet';
import * as _ from 'lodash';

am4core.unuseAllThemes();
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'smart-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  private hourlyChart: am4charts.XYChart;
  private dailyChart: am4charts.XYChart;
  private monthlyChart: am4charts.PieChart;

  existsUser = false;
  timePeriodForm: FormGroup;
  mes: any = (new Date().getMonth() + 1).toString().padStart(2, '0');
  anio: any = (new Date().getFullYear()).toString();
  consumototal = 0;
  costo = 0;
  consumo: any = 0;

  bsConfig = {
    containerClass: 'spark-metrics-datepicker',
    dateInputFormat: 'YYYY-MM-DD'
  };

  siteName: any = 'Colonia Layco, San Salvador';

  /* Maps */
  map: L.Map;

  options = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors'
      }),
    ],
    zoom: 17,
    center: latLng([13.7005625, -89.2193125])
  };

  marker: L.Marker;

  layerGroup: any;
  /** End Map */

  constructor(
    private titleService: Title,
    private settings: SettingsService,
    private zone: NgZone,
    private http: HttpClient,
    public fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private cookieService: CookieService,
    private router: Router,
  ) {
    this.titleService.setTitle(`Dashboard | ${this.settings.getAppSetting('name')}`);

    this.timePeriodForm = this.fb.group({
      rdTimePeriod: ['month', null]
    });
  }

  ngOnInit() {
    this.existsUser = this.cookieService.check(this.settings.getAppSetting('cookie'));
    if (!this.existsUser) { this.router.navigate(['']); }
  }

  ngAfterViewInit() {
    this.zone.run( () => {
      this.getConsumption();
      this.getConsumptionII();
      this.graphMonthlyChart(null);
    });
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.hourlyChart) {
        this.hourlyChart.dispose();
      }
      if (this.dailyChart) {
        this.dailyChart.dispose();
      }
      if (this.monthlyChart) {
        this.monthlyChart.dispose();
      }
    });
  }

  onMapReady(map: L.Map) {
    this.map = map;
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
    L.polygon(layco as any, {color: '#4be9e9'}).addTo(this.map);
    L.polygon(sanjose as any, {color: '#4be9e0'}).addTo(this.map);
    L.polygon(altos as any, {color: '#4be9f0'}).addTo(this.map);

    this.changeSite('Colonia Layco, San Salvador');
  }

  changeSite(site: any) {
    if (site === 'Colonia Layco, San Salvador') {
      if (this.marker) {
        this.map.removeLayer(this.marker);
      }
      this.map.flyTo(new L.LatLng(13.712730, -89.197677), 16);
    } else if (site === 'Residencial San Jose, San Salvador') {
      this.map.flyTo(new L.LatLng(13.704712649715262, -89.2256915808479), 18);
    } else if (site === 'Urbanización Altos de Miramonte, San Salvador') {

      this.map.flyTo(new L.LatLng(13.715413122376717,-89.22146747732242), 18);
    }
  }

  generate(data) {
    let lastConsumption = 0;
    let total = 0;
    this.consumototal = 0;
    this.costo = 0;
    const now = new Date().getTime();

    for (let i = 1; i < data.consumo.length - 1; i++) {
      if (new Date(data.consumo[i].created_at).getTime() > now) break;
      lastConsumption = Number(data.consumo[i].tkw - data.consumo[i - 1].tkw);
      total = total + lastConsumption;
    }

    this.consumototal = Math.round((Math.round(total * 100) / 100) / 2); // TODO: change
    this.costo = Math.round((this.consumototal * 0.19) * 100) / 100;

    this.graphDailyChart(data);
    this.ref.detectChanges();
  }

  getConsumption() {
    const fecha1 = moment().format('YYYY-MM');
    const fecha2 = moment(`${this.anio}-${this.mes}`);
    let mes = Number(fecha2.diff(fecha1, 'months'));
    mes = mes * -1;
    this.http.get(`${this.settings.getAPISetting('url2')}consumomensual/${mes}`).subscribe(data => {
      // this.graphDailyChart(data);
      this.generate(data);
    }, err => {
      console.error(err);
    });
  }

  getConsumptionII() {
    this.http.get(`${this.settings.getAPISetting('url2')}consumoxhora`).subscribe(data => {
      this.graphHourlyChart(data);
    }, err => {
      console.error('GET /consumoxhora', err);
    });
  }

  graphDailyChart(data: any) {
    const self = this;
    am4core.ready(() => {
      am4core.useTheme(am4themes_kelly);
      const container = am4core.create('chartdiv2', am4core.Container);
      container.width = am4core.percent(100);
      container.height = am4core.percent(100);
      container.layout = 'horizontal';

      const chart = container.createChild(am4charts.PieChart);
      chart.fontSize = 11;
      chart.hiddenState.properties.opacity = 0;
      chart.radius = am4core.percent(70);
      chart.innerRadius = am4core.percent(40);
      chart.zIndex = 1;

      const series1 = chart.series.push(new am4charts.PieSeries());
      series1.dataFields.value = 'm3';
      series1.dataFields.category = 'site';
      series1.slices.template.stroke = am4core.color('#fff');
      series1.slices.template.strokeWidth = 2;
      series1.slices.template.strokeOpacity = 1;
      series1.alignLabels = false;
      series1.labels.template.bent = true;
      series1.labels.template.radius = 6;
      series1.labels.template.padding(0, 0, 0, 0);
      series1.hiddenState.properties.opacity = 1;
      series1.hiddenState.properties.endAngle = -90;
      series1.hiddenState.properties.startAngle = -90;

      const a = _.random(15, 35, true);
      const b = _.random(10, 30, true);
      const c = 100 - (a + b);
      chart.data = [
        {
          site: 'Colonia Layco, San Salvador',
          m3: Math.round(this.consumototal * (c / 100))
        },
        {
          site: 'Residencial San Jose, San Salvador',
          m3: Math.round(this.consumototal * (b / 100))
        },
        {
          site: 'Urbanización Altos de Miramonte, San Salvador',
          m3: Math.round(this.consumototal *(a / 100))
        },
      ];
      self.monthlyChart = chart;
    });
  }


  /* graphDailyChart(data: any) {
    const self = this;
    const now = new Date().getTime();
    let date;
    am4core.ready(() => {
      am4core.useTheme(am4themes_kelly);
      const chart = am4core.create('chartdiv2', am4charts.XYChart);

      const generateChartData = () => {
        const chartData: any = [];
        let lastConsumption = 0;

        for (let i = 1; i < data.consumo.length - 1; i++) {
          if (new Date(data.consumo[i].created_at).getTime() > now) break;
          const day = data.consumo[i].dia;
          lastConsumption = Math.round((data.consumo[i].tkw - data.consumo[i - 1].tkw) / 4);
          chartData.push({
            day,
            lastConsumption
          });
        }

        date = new Date(data.consumo[3].created_at).toLocaleString('default', { month: 'long' });
        return chartData;
      };

      chart.data = generateChartData();

      // Create axes
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'day';
      categoryAxis.title.text = 'Dias del mes';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 20;
      categoryAxis.renderer.cellStartLocation = 0.1;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = 'KWH';

      // Create series
      const createSeries = (field, name, stacked) => {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = 'day';
        series.name = name;
        series.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';
        series.stacked = stacked;
        series.columns.template.width = am4core.percent(95);
        series.fill = am4core.color('#4be9e9');
        series.stroke = am4core.color('#4be9e9');
      };

      createSeries('lastConsumption', String(date), false);

      chart.legend = new am4charts.Legend();
      self.monthlyChart = chart;
    });
  } */

  graphHourlyChart(value: any) {
    const self = this;
    const data = [];
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        data.push(value[key]);
      }
    }

    am4core.ready(() => {
      am4core.useTheme(am4themes_kelly);
      const chart = am4core.create('chartdiv3', am4charts.XYChart);

      const generateChartData = () => {
        const chartData: any = [];
        let consumption = 0;
        for (let i = 1; i < data.length; i++) {
          const hour = new Date(data[i].hourly_record_createdAt).getHours();
          consumption = data[i].tkw_hourly - data[i - 1].tkw_hourly;
          chartData.push({
            hour,
            consumption
          });
        }
        return chartData;
      };

      chart.data = generateChartData();

      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'hour';
      categoryAxis.title.text = 'Hour of the day';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 20;
      categoryAxis.renderer.cellStartLocation = 0.1;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = 'kWh';

      function createSeries(field, name, stacked) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = 'hour';
        series.name = name;
        series.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';
        series.stacked = stacked;
        series.columns.template.width = am4core.percent(95);
      }

      createSeries('consumption', 'Consumo', false);

      chart.legend = new am4charts.Legend();
      self.hourlyChart = chart;
    });
  }

  graphMonthlyChart(dataChart: any) {
    const now = new Date().getMonth();
    am4core.ready(() => {
      am4core.useTheme(am4themes_kelly);

      const dummyMonths = [
        {
          country: 'Jan',
          litres: 501.9
        }, {
          country: 'Feb',
          litres: 301.9
        }, {
          country: 'Mar',
          litres: 201.1
        }, {
          country: 'Apr',
          litres: 165.8
        }, {
          country: 'May',
          litres: 139.9
        }, {
          country: 'Jun',
          litres: 128.3
        }, {
          country: 'Jul',
          litres: 501.9
        }, {
          country: 'Aug',
          litres: 301.9
        }, {
          country: 'Sep',
          litres: 201.1
        }, {
          country: 'Oct',
          litres: 165.8
        }, {
          country: 'Nov',
          litres: 139.9
        }, {
          country: 'Dec',
          litres: 128.3
        }
      ];

      const data = [{
        country: 'Dummy',
        disabled: true,
        litres: 1000,
        color: am4core.color('#dadada'),
        opacity: 0.3,
        strokeDasharray: '4,4'
      }, ...dummyMonths.slice(0, now)];

      const container = am4core.create('chartdiv', am4core.Container);
      container.width = am4core.percent(100);
      container.height = am4core.percent(100);
      container.layout = 'horizontal';

      const chart1 = container.createChild(am4charts.PieChart);
      chart1.fontSize = 11;
      chart1.hiddenState.properties.opacity = 0;
      chart1.data = data;
      chart1.radius = am4core.percent(70);
      chart1.innerRadius = am4core.percent(40);
      chart1.zIndex = 1;

      const series1 = chart1.series.push(new am4charts.PieSeries());
      series1.dataFields.value = 'litres';
      series1.dataFields.category = 'country';
      series1.colors.step = 2;
      series1.alignLabels = false;
      series1.labels.template.bent = true;
      series1.labels.template.radius = 3;
      series1.labels.template.padding(0, 0, 0, 0);

      const sliceTemplate1 = series1.slices.template;
      sliceTemplate1.cornerRadius = 5;
      sliceTemplate1.draggable = true;
      sliceTemplate1.inert = true;
      sliceTemplate1.propertyFields.fill = 'color';
      sliceTemplate1.propertyFields.fillOpacity = 'opacity';
      sliceTemplate1.propertyFields.stroke = 'color';
      sliceTemplate1.propertyFields.strokeDasharray = 'strokeDasharray';
      sliceTemplate1.strokeWidth = 1;
      sliceTemplate1.strokeOpacity = 1;

      series1.ticks.template.disabled = true;

      sliceTemplate1.states.getKey('active').properties.shiftRadius = 0;

      const separatorLine = container.createChild(am4core.Line);
      separatorLine.x1 = 0;
      separatorLine.y2 = 300;
      separatorLine.strokeWidth = 3;
      separatorLine.stroke = am4core.color('#dadada');
      separatorLine.valign = 'middle';
      separatorLine.strokeDasharray = '5,5';

      const dragText = container.createChild(am4core.Label);
      dragText.text = 'Drag slices over the line';
      dragText.rotation = 90;
      dragText.valign = 'middle';
      dragText.align = 'center';
      dragText.paddingBottom = 5;

      const chart2 = container.createChild(am4charts.PieChart);
      chart2.hiddenState.properties.opacity = 0;
      chart2.fontSize = 11;
      chart2.radius = am4core.percent(70);
      chart2.data = data;
      chart2.innerRadius = am4core.percent(40);
      chart2.zIndex = 1;

      const series2 = chart2.series.push(new am4charts.PieSeries());
      series2.dataFields.value = 'litres';
      series2.dataFields.category = 'country';
      series2.colors.step = 2;
      series2.alignLabels = false;
      series2.labels.template.bent = true;
      series2.labels.template.radius = 3;
      series2.labels.template.padding(0, 0, 0, 0);
      series2.labels.template.propertyFields.disabled = 'disabled';

      const sliceTemplate2 = series2.slices.template;
      sliceTemplate2.copyFrom(sliceTemplate1);

      series2.ticks.template.disabled = true;

      container.events.on('maxsizechanged', () => {
        chart1.zIndex = 0;
        separatorLine.zIndex = 1;
        dragText.zIndex = 2;
        chart2.zIndex = 3;
      });

      const toggleDummySlice = (series) => {
        let show = true;
        for (let i = 1; i < series.dataItems.length; i++) {
          const dataItem = series.dataItems.getIndex(i);
          if (dataItem.slice.visible && !dataItem.slice.isHiding) {
            show = false;
          }
        }

        const dummySlice = series.dataItems.getIndex(0);
        if (show) {
          dummySlice.show();
        } else {
          dummySlice.hide();
        }
      };

      const handleDragStop = (event) => {
        const targetSlice = event.target;
        let dataItem1;
        let dataItem2;
        let slice1;
        let slice2;

        if (series1.slices.indexOf(targetSlice) !== -1) {
          slice1 = targetSlice;
          slice2 = series2.dataItems.getIndex(targetSlice.dataItem.index).slice;
        } else if (series2.slices.indexOf(targetSlice) !== -1) {
          slice1 = series1.dataItems.getIndex(targetSlice.dataItem.index).slice;
          slice2 = targetSlice;
        }

        dataItem1 = slice1.dataItem;
        dataItem2 = slice2.dataItem;

        const series1Center = am4core.utils.spritePointToSvg({ x: 0, y: 0 }, series1.slicesContainer);
        const series2Center = am4core.utils.spritePointToSvg({ x: 0, y: 0 }, series2.slicesContainer);

        const series1CenterConverted = am4core.utils.svgPointToSprite(series1Center, series2.slicesContainer);
        const series2CenterConverted = am4core.utils.svgPointToSprite(series2Center, series1.slicesContainer);

        const targetSlicePoint = am4core.utils.spritePointToSvg({ x: targetSlice.tooltipX, y: targetSlice.tooltipY }, targetSlice);

        if (targetSlice === slice1) {
          if (targetSlicePoint.x > container.pixelWidth / 2) {
            const value = dataItem1.value;

            dataItem1.hide();

            const animation = slice1.animate([
              { property: 'x', to: series2CenterConverted.x },
              { property: 'y', to: series2CenterConverted.y }
            ], 400);
            animation.events.on('animationprogress', () => {
              slice1.hideTooltip();
            });

            slice2.x = 0;
            slice2.y = 0;

            dataItem2.show();
          } else {
            slice1.animate([{ property: 'x', to: 0 }, { property: 'y', to: 0 }], 400);
          }
        }
        if (targetSlice === slice2) {
          if (targetSlicePoint.x < container.pixelWidth / 2) {

            const value = dataItem2.value;

            dataItem2.hide();

            const animation = slice2.animate([
              { property: 'x', to: series1CenterConverted.x },
              { property: 'y', to: series1CenterConverted.y }
            ], 400);
            animation.events.on('animationprogress', () => {
              slice2.hideTooltip();
            });

            slice1.x = 0;
            slice1.y = 0;
            dataItem1.show();
          } else {
            slice2.animate([{ property: 'x', to: 0 }, { property: 'y', to: 0 }], 400);
          }
        }

        toggleDummySlice(series1);
        toggleDummySlice(series2);

        series1.hideTooltip();
        series2.hideTooltip();
      };

      sliceTemplate1.events.on('dragstop', (event) => {
        handleDragStop(event);
      });

      series2.events.on('datavalidated', () => {
        const dummyDataItem = series2.dataItems.getIndex(0);
        dummyDataItem.show(0);
        dummyDataItem.slice.draggable = false;
        dummyDataItem.slice.tooltipText = undefined;

        for (let i = 1; i < series2.dataItems.length; i++) {
          series2.dataItems.getIndex(i).hide(0);
        }
      });

      series1.events.on('datavalidated', () => {
        const dummyDataItem = series1.dataItems.getIndex(0);
        dummyDataItem.hide(0);
        dummyDataItem.slice.draggable = false;
        dummyDataItem.slice.tooltipText = undefined;
      });
    });
  }

}

const layco = [
  [
    13.714374382305948,
    -89.20348820085808,
  ],
  [
    13.711729102597488,
    -89.2037371971968,
  ],
  [
    13.711627660599945,
    -89.20230747628186,
  ],
  [
    13.711448186187724,
    -89.20041189124822,
  ],
  [
    13.711346744068834,
    -89.19911871671243,
  ],
  [
    13.711487202376418,
    -89.19861269189452,
  ],
  [
    13.711479399139463,
    -89.19822714917541,
  ],
  [
    13.711331137584054,
    -89.19699019961973,
  ],
  [
    13.710956776354664,
    -89.1930511825208,
  ],
  [
    13.712717258136834,
    -89.1928317769984,
  ],
  [
    13.712443516423619,
    -89.19006315462597,
  ],
  [
    13.71327840627562,
    -89.1899884254224,
  ],
  [
    13.71384221488772,
    -89.19733499166583,
  ],
  [
    13.714374382305948,
    -89.20348820085808,
  ]
];

const sanjose = [
  [
    13.703656843116562,
    -89.2257321310056,
  ],
  [
    13.703775030658505,
    -89.22514415371718,
  ],
  [
    13.705536017986546,
    -89.22492518286464,
  ],
  [
    13.705579353080012,
    -89.22503061327505,
  ],
  [
    13.70529570505721,
    -89.2256104805318,
  ],
  [
    13.705398133549068,
    -89.22609708242564,
  ],
  [
    13.705291765499567,
    -89.22630388823069,
  ],
  [
    13.703656843116562,
    -89.2257321310056,
  ]
];

const altos = [
  [
    13.716199584451857,
    -89.22169608536436,
  ],
  [
    13.715060619218079,
    -89.22226306411628,
  ],
  [
    13.714579824858617,
    -89.22108105756529,
  ],
  [
    13.715158645132249,
    -89.22101378889981,
  ],
  [
    13.715401375789398,
    -89.22054290824133,
  ],
  [
    13.71560676307304,
    -89.2204372003383,
  ],
  [
    13.716199584451857,
    -89.22169608536436,
  ]
];
