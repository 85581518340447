import { Component} from '@angular/core';
import { scrollToTop, toggleFullscreen } from '../../utils/utils.functions';

@Component({
  selector: 'smart-fab-shortcut',
  templateUrl: './fab-shortcut.component.html'
})
export class FabShortcutComponent {

  constructor() { }

  scrollTop($event: MouseEvent) {
    $event.preventDefault();
    scrollToTop(150);
  }
  fullScreen($event: MouseEvent) {
    $event.preventDefault();
    toggleFullscreen();
  }
  printPage($event: MouseEvent) {
    $event.preventDefault();
    window.print();
  }

}
