import { Component, OnInit } from '@angular/core';
import { SettingsService } from './@core/utils';
import { CookieService } from 'ngx-cookie-service';
import { Router, NavigationEnd } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import * as CryptoJS from 'crypto-js';
import * as _ from 'lodash';

import { filter } from 'rxjs/operators';

@Component({
  selector: 'smart-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'spark-metrics';

  constructor(
    private cookieService: CookieService,
    public settings: SettingsService,
    private router: Router,
    private platformLocation: PlatformLocation,
    private jwtHelper: JwtHelperService,
    private swUpdate: SwUpdate,
  ) { }

  isNewVersion() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((event: UpdateAvailableEvent) => {
        if (confirm(`Nueva version de Spark Metrics detectada, quieres actualizar?`)) {
          window.location.reload();
        }
      });
    }
  }

  ngOnInit() {
    this.decryptSession();
    this.checkSession();
  }

  decryptSession() {
    if (this.cookieService.check(this.settings.getAppSetting('cookie'))) {
      const sessionEncrypted = this.cookieService.get(this.settings.getAppSetting('cookie'));
      const decrypt = CryptoJS.AES.decrypt(sessionEncrypted.toString(), this.settings.getAppSetting('secret'));
      const token = decrypt.toString(CryptoJS.enc.Utf8);
      const decodeToken = this.jwtHelper.decodeToken(token);
      this.settings.setUserSetting('token', token);
      this.settings.setUserSetting('login', decodeToken.user);
    }
  }

  checkSession() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      if (this.cookieService.check(this.settings.getAppSetting('cookie'))) {
        const sessionEncrypted = this.cookieService.get(this.settings.getAppSetting('cookie'));
        const decrypt = CryptoJS.AES.decrypt(sessionEncrypted.toString(), this.settings.getAppSetting('secret'));
        if (_.isEqual(this.settings.getUserSetting('token'), decrypt.toString(CryptoJS.enc.Utf8))) {
          // Without changes in session
          // Nothing to do
        } else {
          // If session has changed, then reload
          window.location.reload();
        }
      } else {
        if (this.settings.getUserSetting('login').hasOwnProperty('userId')) {
          // If no has session but data is load in cache
          window.location.reload();
        }
      }

      this.isNewVersion();
    });
  }
}
