import { Component } from '@angular/core';
import { SettingsService } from '../../../@core/utils';

@Component({
  selector: 'smart-dropdown-user',
  templateUrl: './dropdown-user.component.html',
})
export class DropdownUserComponent {

  constructor(public settings: SettingsService) {}

}
