import { Injectable } from '@angular/core';

@Injectable()
export class SettingsService {

  private user: any;
  private app: any;
  private api: any;
  private cloudinary: any;

  constructor() {

    // User Settings
    this.user = {
      login: [],
      token: '',
      theme: '',
    };

    // App Settings
    this.app = {
      name: 'Spark Metrics',
      cookie: '__skmtcd',
      secret: 'An!ta_1292#',
      // tslint:disable-next-line:max-line-length
      copyright: `${new Date().getFullYear()} © Spark Metrics by&nbsp;<a href="https://proyectosees.com" class="text-primary fw-500" title="EES" target="_blank">Eficiencia Energetica y Seguridad S.A. de C.V.</a> ~ Powered by <a href="http://www.fondepro.gob.sv/" class="text-primary fw-500" title="FONDEPRO" target="_blank"><img class="fondepro-logo" src="/assets/img/fondepro.png" alt="FONDEPRO"></a>`,
    };

    // API Settings
    this.api = {
      url: 'https://spark.seesdata.com:5001/api/',
      url2: 'https://spark.seesdata.com:3000/api/',
      // url: 'http://localhost:3000/api/',
    };

    // cloudinary Settings
    this.cloudinary = {
      url: `https://api.cloudinary.com/v1_1/${'edd-cloud'}/upload`,
      // Cloudinary Folder Names
      folder: 'spark',
      user_folder: 'spark_user-photos',
      pdf_folder: 'spark_documents-pdf',
      company_folder: 'spark_company-logos',
      // Cloudinary Cloud Settings
      cloud_name: 'edd-cloud',
      upload_preset: 'p2laxjur',
    };

  }

  getAppSetting(name) {
    return name ? this.app[name] : this.app;
  }
  getUserSetting(name) {
    return name ? this.user[name] : this.user;
  }
  getAPISetting(name: string) {
    return name ? this.api[name] : this.api;
  }
  getCloudinarySetting(name) {
    return name ? this.cloudinary[name] : this.cloudinary;
  }

  setUserSetting(name, value) {
    if (typeof this.user[name] !== 'undefined') {
      this.user[name] = value;
    }
  }

}
