export const navigationItems = [
  {
    title: 'Inicio',
    tags: 'dashboard',
    icon: 'ni ni-grid',
    routerLink: '/dashboard',
  },
  {
    title: 'Clientes',
    tags: 'clients',
    icon: 'ni ni-users',
    routerLink: '/clients',
  },
  {
    title: 'Mediciones',
    tags: 'metrics',
    icon: 'fal fa-tint',
    routerLink: '/metrics/water',
    /* items: [
      {
        title: 'Alarmas',
        tags: 'alarms',
        icon: 'fal fa-bell',
        routerLink: '/metrics/alarms',
      },
      {
        title: 'Agua',
        tags: 'water',
        icon: 'fal fa-tint',
        routerLink: '/metrics/water',
      },
    ] */
  },
  /* {
    title: 'Controles',
    tags: 'controls',
    icon: 'fal fa-toggle-on',
    routerLink: '/controls',
  }, */
  /* {
    title: 'Análisis',
    tags: 'analysis',
    icon: 'fal fa-chart-pie',
    items: [
      {
        title: 'Resumen',
        tags: 'consumption',
        icon: 'fal fa-plug',
        routerLink: '/analysis/consumption',
      },
      {
        title: 'Dispositivos',
        tags: 'dashboard',
        icon: 'fal fa-lightbulb',
        routerLink: '/analysis/devices',
      },
      {
        title: 'Emisiones',
        tags: 'emissions',
        icon: 'fal fa-leaf',
        routerLink: '/analysis/emissions',
      },
      {
        title: 'Historial',
        tags: 'record',
        icon: 'fal fa-chart-line',
        routerLink: '/analysis/record',
      },
    ]
  }, */
  /* {
    title: 'Reportes',
    tags: 'reports',
    icon: 'fal fa-calendar-alt',
    routerLink: '/reports',
  }, */
  {
    title: 'Ajustes',
    tags: 'settings',
    icon: 'fal fa-cog',
    items: [
      /* {
        title: 'Datos personales',
        tags: 'profile user',
        icon: 'fal fa-id-card',
        routerLink: '/settings/personal',
      }, */
      /* {
        title: 'Seguridad',
        tags: 'security password',
        icon: 'fal fa-lock-alt',
        routerLink: '/settings/security',
      }, */
      /* {
        title: 'Linea Base',
        tags: 'base line',
        icon: 'fal fa-calendar-exclamation',
        routerLink: '/settings/baseline',
      }, */
      /* {
        title: 'Estaciones',
        tags: 'stations',
        icon: 'fal fa-podcast',
        routerLink: '/settings/stations',
      }, */
      /* {
        title: 'Clientes',
        tags: 'clients',
        icon: 'fal fa-users',
        routerLink: '/settings/create-client',
      }, */
      {
        title: 'Usuarios',
        tags: 'users',
        icon: 'fal fa-user-plus',
        routerLink: '/settings/create-user',
      },
    ]
  },

];
